import { render, staticRenderFns } from "./ApprovalCard.vue?vue&type=template&id=f1570a08"
import script from "./ApprovalCard.vue?vue&type=script&lang=js"
export * from "./ApprovalCard.vue?vue&type=script&lang=js"
import style0 from "./ApprovalCard.vue?vue&type=style&index=0&id=f1570a08&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports