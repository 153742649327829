import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.documents),function(document,i){return _c(VCard,{key:i,staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardText,[_c(VToolbar,{staticClass:"white--text text-capitalize text-body-1",attrs:{"color":"primary","dense":""}},[_vm._v(" "+_vm._s(document.fileName)+" - "+_vm._s(document.id)+" "),_c(VSpacer),_c(VBtn,{staticClass:"mx-2 text-capitalize",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.downloadLink(document.base64Attachment)}}},[_vm._v(" Download "),_c(VIcon,{staticClass:"mx-1"},[_vm._v("mdi-download")])],1)],1),(document.fileExtension === 'pdf')?_c('div',_vm._l((_vm.getPages(document.base64Attachment)),function(i){return _c('pdf',{key:i,attrs:{"src":document.base64Attachment,"page":i}})}),1):(_vm.isImage(document.fileExtension))?_c('div',[_c(VImg,{attrs:{"src":document.base64Attachment}})],1):_c('div',[_c(VCard,[_c(VCardText,[_c('div',{staticClass:"text-body-1"},[_vm._v(" Sorry, we cannot display this file. Please download it to view it. ")])])],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }